import {
  ColoredTag as Tag,
  TagBackgroundColor,
} from '@ee-monorepo/shared/ui/tag';
import * as PrismicFields from '@ee-monorepo/prismic/shared/types';
import { TooltipWithPopper } from '@ee-monorepo/prismic/shared/ui';
import { getColor } from '@ee-monorepo/shared/utilities/functions';
import { Lottie } from './lazy/react-lottie-player';
import cn from 'classnames';
import * as prismicH from '@prismicio/helpers';
import { useTablet } from '@ee-monorepo/shared/utilities/hooks';
import { Slice, ButtonVariant } from '@ee-monorepo/prismic/shared/types';
import { storySerializer } from '@ee-monorepo/prismic/shared/serializers';
import { ImageWrapper, SliceWrapper } from '@ee-monorepo/prismic/shared/ui';
import { PrismicButton } from '@ee-monorepo/prismic/shared/ui';
import { PrismicRichText } from '@prismicio/react';
import { richTextSerializerHeading1Override } from '@ee-monorepo/prismic/shared/serializers';
import { usePrequalExperiment } from '@ee-monorepo/growth-experiments';
import { useRouter } from 'next/router';
import { getStyle } from './story.styles';

interface StorySlice extends Slice {
  variation?:
    | 'default'
    | 'storyWithStats'
    | 'prequalFlow'
    | 'storyTextWithImageLink';
  primary: {
    image_side?: string;
    story?: PrismicFields.RichTextField;
    prequal_story?: PrismicFields.RichTextField;
    button_one_link?: PrismicFields.Link;
    button_one_label?: PrismicFields.KeyTextField;
    button_one_inverted_variant?: boolean;
    button_one_variant?: PrismicFields.Select;
    button_two_link?: PrismicFields.Link;
    button_two_label?: PrismicFields.KeyTextField;
    button_two_inverted_variant?: boolean;
    button_two_variant?: PrismicFields.Select;
    story_image?: PrismicFields.Image;
    prequal_story_image?: PrismicFields.Image;
    tag?: PrismicFields.KeyTextField;
    tag_background_color?: PrismicFields.Select;
    title?: PrismicFields.RichTextField;
    prequal_title?: PrismicFields.RichTextField;
    customer_comment_text?: PrismicFields.KeyTextField;
    customer_comment_name?: PrismicFields.KeyTextField;
    customer_comment_merchant?: PrismicFields.KeyTextField;
    customer_comment_portrait?: PrismicFields.Image;
    animation?: PrismicFields.KeyTextField;
    animation_mobile?: PrismicFields.KeyTextField;
    show_header_disclaimer?: boolean;
    prequal_show_header_disclaimer?: boolean;
    header_disclaimer_text?: PrismicFields.RichTextField;
    prequal_header_disclaimer_text?: PrismicFields.RichTextField;
    show_body_disclaimer?: boolean;
    prequal_show_body_disclaimer?: boolean;
    body_disclaimer_text?: PrismicFields.RichTextField;
    prequal_body_disclaimer_text?: PrismicFields.RichTextField;
    stat_heading?: PrismicFields.RichTextField;
    stat_description?: PrismicFields.RichTextField;
    stats_heading_color?: PrismicFields.Select;
    width?: PrismicFields.Select;
    background_color?: PrismicFields.Select;
    is_clickable_icons_variant?: boolean;
  };
  items: {
    stat_heading: PrismicFields.RichTextField;
    stat_description: PrismicFields.RichTextField;
    bullet: PrismicFields.RichTextField;
    industry_name: PrismicFields.KeyTextField;
    industry_icon: PrismicFields.Image;
    industry_link: PrismicFields.Link;
  }[];
}
export interface StoryProps extends PrismicFields.SliceBase {
  slice: StorySlice;
}

enum widthValues {
  'std' = 'standard',
  'full' = 'wider',
}

// eslint-disable-next-line complexity
export function Story(props: StoryProps) {
  const router = useRouter();
  const {
    story,
    prequal_story,
    story_image,
    prequal_story_image,
    tag,
    tag_background_color,
    title,
    prequal_title,
    image_side,
    customer_comment_text,
    customer_comment_name,
    customer_comment_merchant,
    customer_comment_portrait,
    button_one_inverted_variant,
    button_one_label,
    button_one_link,
    button_one_variant,
    button_two_inverted_variant,
    button_two_label,
    button_two_link,
    button_two_variant,
    animation,
    animation_mobile,
    show_header_disclaimer,
    header_disclaimer_text,
    prequal_show_header_disclaimer,
    prequal_header_disclaimer_text,
    show_body_disclaimer,
    body_disclaimer_text,
    prequal_show_body_disclaimer,
    prequal_body_disclaimer_text,
    stats_heading_color,
    width,
    background_color,
    is_clickable_icons_variant,
  } = props.slice.primary;
  const { items, variation } = props.slice;
  const isMobile = useTablet();
  const isImgLeft = image_side === 'left';
  const hasButtonOne = button_one_label && button_one_link;
  const hasButtonTwo = button_two_label && button_two_link;
  const { isPrequal } = usePrequalExperiment();
  const renderCustomerComment = () => {
    return (
      <>
        <div
          className={styles.customerCommentParent}
        />
        <p
          className={styles.customerCommentText}
        >
          {customer_comment_text}
        </p>
        <div
          className={styles.customerImageParent}
        >
          <div
            className={styles.customerImageWrapper}
          >
            <ImageWrapper
              src={customer_comment_portrait?.url}
              alt={customer_comment_portrait?.alt}
              width={customer_comment_portrait?.dimensions?.width}
              height={customer_comment_portrait?.dimensions?.height}
            />
          </div>
          <div>
            <div className={styles.customerName}>
              {customer_comment_name}
            </div>
            <div
              className={styles.customerComment}
            >
              {customer_comment_merchant}
            </div>
          </div>
        </div>
      </>
    );
  };

  const IndustriesList = ({ items, isCompactDesign }) => {
    return (
      <div
        data-testid="industry-list"
        className={styles.industryList}
      >
        {items?.map((item, index) => (
          <div
            key={index}
            onClick={() =>
              item?.industry_link?.url
                ? router.push(item?.industry_link?.url)
                : null
            }
            className={cn(styles.industryItem,
              item?.industry_link?.url && item?.industry_link?.url !== ''
                ? 'cursor-pointer hover:cursor-pointer'
                : null
            )}
            data-testid={'industry-list-item-' + (index + 1)}
          >
            <div className={styles.industryIconParent}>
              <ImageWrapper
                src={item?.industry_icon?.url}
                alt={item?.industry_name}
                width={isCompactDesign ? 24 : 64}
                height={isCompactDesign ? 24 : 64}
                className={styles.industryIcon}
              />
            </div>
            <div className={styles.industryName}
            >
              {item?.industry_name}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const getBulletNumber = (bulletNumber: number) => {
    if (bulletNumber <= 9) {
      return `0${bulletNumber}`;
    }
    return bulletNumber;
  };

  const isWider = width ? width === widthValues.full : false;
  const styles = getStyle(variation, {
    isImgLeft,
    isMobile,
    is_clickable_icons_variant,
    isWider,
    variation,
    background_color,
    hasButtonOne,
    hasButtonTwo,
    tag
  });

  return (
    <div
      data-testid="story-slice"
      data-slice-variation={variation}
      data-slice-index={props.index}
      className={cn(
        background_color && getColor(background_color).backgroundColorClass,
        styles.mainDiv
      )}
    >
      <SliceWrapper className={styles.mainContent}>
        <div
          data-testid="storyContainer" className={styles.storyContainer}>
          <div data-testid="storySection" className={styles.storySection}>
            <Tag
              className={styles.tags}
              backgroundColor={tag_background_color as TagBackgroundColor}
              text={tag}
            />
            {!isPrequal && show_header_disclaimer ? (
              <TooltipWithPopper
                mainContent={title}
                mainContentSerializer={storySerializer}
                popperContent={header_disclaimer_text}
                elementType={title[0]?.type}
              />
            ) : isPrequal &&
              variation === 'prequalFlow' &&
              prequal_show_header_disclaimer ? (
              <TooltipWithPopper
                mainContent={prequal_title}
                mainContentSerializer={storySerializer}
                popperContent={prequal_header_disclaimer_text}
                elementType={prequal_title[0]?.type}
              />
            ) : (
              <div
                data-testid="story-field-title-without-popper"
                    className={styles.titleWithoutPopper}
              >
                <PrismicRichText
                  field={
                    isPrequal && variation === 'prequalFlow'
                      ? prequal_title
                      : title
                  }
                  components={storySerializer}
                />
              </div>
            )}
            {!isPrequal && show_body_disclaimer ? (
              <TooltipWithPopper
                mainContent={story}
                mainContentSerializer={storySerializer}
                popperContent={body_disclaimer_text}
                elementType={story[0]?.type}
                iconTooltipColor="SnapGreen"
                mainContentColor="text-SnapGrey500"
              />
            ) : isPrequal &&
              variation === 'prequalFlow' &&
              prequal_show_body_disclaimer ? (
              <TooltipWithPopper
                mainContent={prequal_story}
                mainContentSerializer={storySerializer}
                popperContent={prequal_body_disclaimer_text}
                elementType={prequal_story[0]?.type}
              />
            ) : story ? (
              <PrismicRichText
                field={
                  isPrequal && variation === 'prequalFlow'
                    ? prequal_story
                    : story
                }
                components={storySerializer}
              />
            ) : null}
            {variation === 'storyWithStats'
              ? items?.map((item, index) => {
                  return (
                    <div
                      className={styles.stat}
                      key={index}
                    >
                      {prismicH.isFilled.richText(item?.stat_heading) && (
                        <span
                          className={cn(
                            getColor(stats_heading_color).textColorClass,
                            styles.statHeading
                          )}
                        >
                          <PrismicRichText
                            field={item?.stat_heading}
                            components={storySerializer}
                          />
                        </span>
                      )}
                      {prismicH.isFilled.richText(item?.stat_description) && (
                        <span
                          className={styles.statDescription}
                        >
                          <PrismicRichText
                            field={item?.stat_description}
                            components={richTextSerializerHeading1Override}
                          />
                        </span>
                      )}
                    </div>
                  );
                })
              : items?.map((item, index) => {
                  return item?.bullet?.length > 0 ? (
                    <div
                      className={styles.bulletPointSection}
                      key={index}
                    >
                      <span
                        className={styles.bulletNumber}
                      >
                        {getBulletNumber(index + 1)}
                      </span>
                      <span
                        className={styles.bulletPoint}
                      >
                        <PrismicRichText
                          field={item?.bullet}
                          components={storySerializer}
                        />
                      </span>
                    </div>
                  ) : null;
                })}

            {variation === 'storyTextWithImageLink' &&
              is_clickable_icons_variant && (
                <IndustriesList items={items} isCompactDesign={true} />
              )}
            {(variation !== 'storyTextWithImageLink' ||
              (variation === 'storyTextWithImageLink' && !isMobile)) && (
              <div className={styles.storyButtonParent}>
                {hasButtonOne && (
                  <PrismicButton
                    variant={button_one_variant as ButtonVariant}
                    inverseMode={button_one_inverted_variant}
                    link={button_one_link}
                    showButton={true}
                    fullWidth={isMobile}
                    label={button_one_label}
                    data-testid="storyButton"
                  />
                )}
                {hasButtonTwo && (
                  <PrismicButton
                    link={button_two_link}
                    className={styles.storyButtonTwo}
                    variant={button_two_variant as ButtonVariant}
                    inverseMode={button_two_inverted_variant}
                    fullWidth={isMobile}
                    showButton={true}
                    label={button_two_label}
                    data-testid="storyButton"
                  />
                )}
              </div>
            )}
            {customer_comment_text && renderCustomerComment()}
          </div>
          <div
            data-testid="imageSection"
            className={styles.imageSection}
          >
            <div
              className={styles.storyImageDesktop}
            >
              {animation ? (
                <Lottie
                  loop
                  path={animation}
                  play
                  data-testid="lottieWeb"
                  style={{}}
                />
              ) : (
                <ImageWrapper
                  src={
                    isPrequal && variation === 'prequalFlow'
                      ? prequal_story_image?.url
                      : story_image?.url
                  }
                  alt={
                    isPrequal && variation === 'prequalFlow'
                      ? prequal_story_image?.alt
                      : story_image?.alt
                  }
                  width={
                    isPrequal && variation === 'prequalFlow'
                      ? prequal_story_image?.dimensions?.width
                      : story_image?.dimensions?.width
                  }
                  height={
                    isPrequal && variation === 'prequalFlow'
                      ? prequal_story_image?.dimensions?.height
                      : story_image?.dimensions?.height
                  }
                />
              )}
            </div>
            <div
              className={styles.storyImageMobile}
            >
              {animation_mobile ? (
                <Lottie
                  loop
                  path={animation_mobile}
                  play
                  data-testid="lottieMobile"
                  style={{}}
                />
              ) : (
                <ImageWrapper
                  className="w-full"
                  src={
                    isPrequal && variation === 'prequalFlow'
                      ? prequal_story_image?.mobile?.url
                      : story_image?.mobile?.url
                  }
                  alt={
                    isPrequal && variation === 'prequalFlow'
                      ? prequal_story_image?.mobile?.alt
                      : story_image?.mobile?.alt
                  }
                  width={
                    isPrequal && variation === 'prequalFlow'
                      ? prequal_story_image?.mobile?.dimensions?.width
                      : story_image?.mobile?.dimensions?.width
                  }
                  height={
                    isPrequal && variation === 'prequalFlow'
                      ? prequal_story_image?.mobile?.dimensions?.height
                      : story_image?.mobile?.dimensions?.height
                  }
                />
              )}
            </div>
            {variation === 'storyTextWithImageLink' && (
              <>
                {!is_clickable_icons_variant && (
                  <IndustriesList items={items} isCompactDesign={false} />
                )}
                {isMobile && (
                  <div className={styles.storyIconButtonParent}>
                    {hasButtonOne && (
                      <PrismicButton
                        variant={button_one_variant as ButtonVariant}
                        inverseMode={button_one_inverted_variant}
                        link={button_one_link}
                        showButton={true}
                        fullWidth={isMobile}
                        label={button_one_label}
                        data-testid="storyButton"
                      />
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </SliceWrapper>
    </div>
  );
}

export default Story;
