/* eslint-disable complexity */
import * as PrismicFields from '@ee-monorepo/prismic/shared/types';
interface GetStyleProps {
    isImgLeft?: boolean;
    is_clickable_icons_variant?: boolean;
    isMobile?: boolean;
    isWider?: boolean;
    hasButtonOne?: PrismicFields.Link;
    hasButtonTwo?: PrismicFields.Link;
    variation: string;
    background_color?: string;
    tag?: PrismicFields.KeyTextField;
}

interface StoryStyle {
    mainDiv?: string;
    mainContent?: string;
    storyContainer?: string;
    storySection?: string;
    tags?: string;
    stat?: string;
    statHeading?: string;
    statDescription?: string;
    title?: string;
    titleWithoutPopper?: string;
    descriptionWithoutPopper?: string;
    storyIconButtonParent?: string;
    storyButtonParent?: string;
    storyButtonOne?: string;
    storyButtonTwo?: string;
    bulletPointSection?: string;
    bulletPoint?: string;
    bulletNumber?: string;
    imageSection?: string;
    storyImageDesktop?: string;
    storyImageMobile?: string;
    // Customer Comment section
    customerCommentParent?: string;
    customerComment?: string;
    customerCommentText?: string;
    customerImageParent?: string;
    customerImageWrapper?: string;
    customerName?: string;
    //   industryList
    industryList?: string;
    industryItem?: string;
    industryIconParent?: string;
    industryIcon?: string;
    industryName?: string;
}

export const getStyle = (
    variation:
        | 'default'
        | 'storyWithStats'
        | 'prequalFlow'
        | 'storyTextWithImageLink',
    props: GetStyleProps
): StoryStyle => {
    const styleObject = styles(props);
    return styleObject[variation] || styleObject.default;
};

const styles = (props: GetStyleProps): { [key: string]: StoryStyle } => {
    return {
        default: {
            mainDiv: '',
            mainContent: 'snap-container-no-pad',
            storyContainer: `mx-0 flex flex-col sm:flex-row flex-wrap
            ${props.isWider ? 'justify-between lg:flex-nowrap' : 'justify-center'}`,
            storySection: `flex flex-col justify-center w-full md:mt-0 sm:mt-6 lg:px-4 md:px-3 sm:px-4 order-1
                ${props.isImgLeft ? 'md:order-1' : 'offset-lg-1 md:order-none'}
                ${!props.isWider ? 'md:w-1/2 lg:w-2/5' : ''}`,
            tags: `${props.tag !== null ? 'mr-6 mb-6' : ''}`,
            stat: 'mt-5',
            statHeading: 'headingMHeavy leading-[48px]',
            statDescription: 'headingM inline',
            title: '',
            titleWithoutPopper: 'text-SnapDarkBlue mb-2',
            descriptionWithoutPopper: '',
            storyIconButtonParent: `${props.hasButtonOne !== null ? 'mt-6 lg:px-4 sm:px-4' : ''}`,
            storyButtonParent: `${props.hasButtonOne !== null ? 'mt-6' : ''}`,
            storyButtonOne: '',
            storyButtonTwo: 'block lg:inline lg:ml-12 mt-6 lg:mt-0',
            bulletPointSection: 'flex mt-5',
            bulletPoint: '[&>*]:headingM [&>*]:inline',
            bulletNumber: 'headingMHeavy text-SnapBlue mr-2',
            imageSection: `w-full flex flex-col justify-start lg:px-4 md:px-3 sm:px-0 order-none 
                ${props.isImgLeft ? 'offset-lg-1 md:order-0' : 'md:order-1'}
                ${!props.isWider ? 'md:w-1/2 lg:w-2/5' : ''}`,
            storyImageDesktop: `md:block hidden ${props.isWider ? 'text-right' : ''}`,
            storyImageMobile: 'block md:hidden',
            // Customer Comment section
            customerCommentParent: 'divider mt-8 md:mt-10 mb-6',
            customerComment: 'secondaryFont text-SnapGrey400',
            customerCommentText: 'secondaryFont text-SnapGrey500',
            customerImageParent: 'flex flex-row',
            customerImageWrapper: 'inline-block mr-2',
            customerName: 'bodyHeavyFont',
            //   industryList
            industryList: 'grid-cols-3 text-center sm:px-4 lg:w-4/5 pt-6 md:pt-0 grid grid-rows-2 gap-4',
            industryItem: 'grid-rows-1 justify-center p-4 m-auto',
            industryIconParent: 'h-16 w-[85px]',
            industryIcon: '',
            industryName: 'pt-4 font-bold',
        },
        storyWithStats: {
            mainDiv: '',
            mainContent: 'snap-container-no-pad',
            storyContainer: `mx-0 flex flex-col sm:flex-row flex-wrap
            ${props.isWider ? 'justify-between lg:flex-nowrap' : 'justify-center'}`,
            storySection: `flex flex-col justify-center w-full md:mt-0 sm:mt-6 lg:px-4 md:px-3 sm:px-4 order-1
                ${props.isImgLeft ? 'md:order-1' : 'offset-lg-1 md:order-none'}
                ${!props.isWider ? 'md:w-1/2 lg:w-2/5' : ''}`,
            tags: `${props.tag !== null ? 'mr-6 mb-6' : ''}`,
            stat: 'mt-5',
            statHeading: 'headingMHeavy leading-[48px]',
            statDescription: 'headingM inline',
            title: '',
            titleWithoutPopper: 'text-SnapDarkBlue mb-2',
            descriptionWithoutPopper: '',
            storyIconButtonParent: `${props.hasButtonOne !== null ? 'mt-6 lg:px-4 sm:px-4' : ''}`,
            storyButtonParent: `${props.hasButtonOne !== null ? 'mt-6' : ''}`,
            storyButtonOne: '',
            storyButtonTwo: 'block lg:inline lg:ml-12 mt-6 lg:mt-0',
            bulletPointSection: 'flex mt-5',
            bulletPoint: '[&>*]:headingM [&>*]:inline',
            bulletNumber: 'headingMHeavy text-SnapBlue mr-2',
            imageSection: `w-full flex flex-col justify-start lg:px-4 md:px-3 sm:px-0 order-none 
                ${props.isImgLeft ? 'offset-lg-1 md:order-0' : 'md:order-1'}
                ${!props.isWider ? 'md:w-1/2 lg:w-2/5' : ''}`,
            storyImageDesktop: `md:block hidden ${props.isWider ? 'text-right' : ''}`,
            storyImageMobile: 'block md:hidden',
            // Customer Comment section
            customerCommentParent: 'divider mt-8 md:mt-10 mb-6',
            customerComment: 'secondaryFont text-SnapGrey400',
            customerCommentText: 'secondaryFont text-SnapGrey500',
            customerImageParent: 'flex flex-row',
            customerImageWrapper: 'inline-block mr-2',
            customerName: 'bodyHeavyFont',
            //   industryList
            industryList: 'grid-cols-3 text-center sm:px-4 lg:w-4/5 pt-6 md:pt-0 grid grid-rows-2 gap-4',
            industryItem: 'grid-rows-1 justify-center p-4 m-auto',
            industryIconParent: 'h-16 w-[85px]',
            industryIcon: '',
            industryName: 'pt-4 font-bold',

        },
        prequalFlow: {
            mainDiv: '',
            mainContent: 'snap-container-no-pad',
            storyContainer: `mx-0 flex flex-col sm:flex-row flex-wrap
            ${props.isWider ? 'justify-between lg:flex-nowrap' : 'justify-center'}`,
            storySection: `flex flex-col justify-center w-full md:mt-0 sm:mt-6 lg:px-4 md:px-3 sm:px-4 order-1
                ${props.isImgLeft ? 'md:order-1' : 'offset-lg-1 md:order-none'}
                ${!props.isWider ? 'md:w-1/2 lg:w-2/5' : ''}`,
            tags: `${props.tag !== null ? 'mr-6 mb-6' : ''}`,
            stat: 'mt-5',
            statHeading: 'headingMHeavy leading-[48px]',
            statDescription: 'headingM inline',
            title: '',
            titleWithoutPopper: 'text-SnapDarkBlue mb-2',
            descriptionWithoutPopper: '',
            storyIconButtonParent: `${props.hasButtonOne !== null ? 'mt-6 lg:px-4 sm:px-4' : ''}`,
            storyButtonParent: `${props.hasButtonOne !== null ? 'mt-6' : ''}`,
            storyButtonOne: '',
            storyButtonTwo: 'block lg:inline lg:ml-12 mt-6 lg:mt-0',
            bulletPointSection: 'flex mt-5',
            bulletPoint: '[&>*]:headingM [&>*]:inline',
            bulletNumber: 'headingMHeavy text-SnapBlue mr-2',
            imageSection: `w-full flex flex-col justify-start lg:px-4 md:px-3 sm:px-0 order-none 
                ${props.isImgLeft ? 'offset-lg-1 md:order-0' : 'md:order-1'}
                ${!props.isWider ? 'md:w-1/2 lg:w-2/5' : ''}`,
            storyImageDesktop: `md:block hidden ${props.isWider ? 'text-right' : ''}`,
            storyImageMobile: 'block md:hidden',
            // Customer Comment section
            customerCommentParent: 'divider mt-8 md:mt-10 mb-6',
            customerComment: 'secondaryFont text-SnapGrey400',
            customerCommentText: 'secondaryFont text-SnapGrey500',
            customerImageParent: 'flex flex-row',
            customerImageWrapper: 'inline-block mr-2',
            customerName: 'bodyHeavyFont',
            //   industryList
            industryList: 'grid-cols-3 text-center sm:px-4 lg:w-4/5 pt-6 md:pt-0 grid grid-rows-2 gap-4',
            industryItem: 'grid-rows-1 justify-center p-4 m-auto',
            industryIconParent: 'h-16 w-[85px]',
            industryIcon: '',
            industryName: 'pt-4 font-bold',

        },
        storyTextWithImageLink: {
            mainDiv: '',
            mainContent: `snap-container-no-pad ${props.background_color ? 'pb-8 md:py-12' : ''}`,
            storyContainer: `mx-0 flex flex-col sm:flex-row flex-wrap
                ${props.is_clickable_icons_variant && props.isImgLeft
                    ? 'justify-evenly lg:flex-nowrap'
                    : props.isWider || props.is_clickable_icons_variant
                        ? 'justify-between lg:flex-nowrap'
                        : 'justify-center'}`,
            storySection: `flex flex-col justify-center w-full md:mt-0 sm:mt-6 lg:px-4 md:px-3 sm:px-4 order-1
                ${props.is_clickable_icons_variant && !props.isImgLeft ? 'xs:order-2 md:order-none lg:pl-0' : ''}
                ${props.isImgLeft ? 'md:order-1' : 'offset-lg-1 md:order-none'}
                ${!props.isWider ? 'md:w-1/2 lg:w-2/5' : ''}`,
            tags: `${props.tag !== null ? 'mr-6 mb-6' : ''}`,
            stat: 'mt-5',
            statHeading: 'headingMHeavy leading-[48px]',
            statDescription: 'headingM inline',
            title: '',
            titleWithoutPopper: 'text-SnapDarkBlue mb-2',
            descriptionWithoutPopper: '',
            storyIconButtonParent: `${props.hasButtonOne !== null ? 'mt-6 lg:px-4 sm:px-4' : ''}`,
            storyButtonParent: `${props.hasButtonOne !== null ? 'mt-6' : ''} ${props.is_clickable_icons_variant ? 'mt-6 m-auto' : ''}`,
            storyButtonOne: '',
            storyButtonTwo: 'block lg:inline lg:ml-12 mt-6 lg:mt-0',
            bulletPointSection: 'flex mt-5',
            bulletPoint: '[&>*]:headingM [&>*]:inline',
            bulletNumber: 'headingMHeavy text-SnapBlue mr-2',
            imageSection: `${props.is_clickable_icons_variant ? `xs:pt-4 md:pt-0 xs:w-[2/5] 
                ${!props.isImgLeft ? 'm-auto sm:w-auto xs:order-1 md:order-none' : ''}` :
                'w-full order-2 md:order-none'} flex flex-col justify-start lg:px-4 md:px-3 sm:px-0'
                ${!props.isWider ? 'md:w-1/2 lg:w-2/5' : ''}`,
            storyImageDesktop: `${props.is_clickable_icons_variant ? 'p-4' : 'md:block hidden'}
                ${props.isWider ? 'text-right' : ''}`,
            storyImageMobile: 'block md:hidden',
            // Customer Comment section
            customerCommentParent: 'divider mt-8 md:mt-10 mb-6',
            customerComment: 'secondaryFont text-SnapGrey400',
            customerCommentText: 'secondaryFont text-SnapGrey500',
            customerImageParent: 'flex flex-row',
            customerImageWrapper: 'inline-block mr-2',
            customerName: 'bodyHeavyFont',
            //   industryList
            industryList: `${props.is_clickable_icons_variant
                ? 'grid-cols-2 justify-start pt-6'
                : 'grid-cols-3 text-center sm:px-4 lg:w-4/5 pt-6 md:pt-0'} grid grid-rows-2 gap-4`,
            industryItem: `${props.is_clickable_icons_variant
                ? 'group flex justify-start items-center py-1 px-2 rounded-full border-2 border-SnapGreen hover:bg-SnapGreen hover:text-white text-SnapSuccess'
                : 'justify-center p-4 m-auto text-SnapDarkBlue'} grid-rows-1`,
            industryIconParent: `${props.is_clickable_icons_variant ? 'mr-1' : 'h-16 w-[85px]'}`,
            industryIcon: `${props.is_clickable_icons_variant ? 'group-hover:brightness-0 group-hover:invert' : ''}`,
            industryName: `${props.is_clickable_icons_variant ? 'bodyHeavyFont' : 'pt-4'} font-bold`,

        },
        // Add more variations as needed
    };
};
